<template>
<div class="legal nav-spcr">
    <section class="post">
        <div class="major">
            <h1 class="font-weight-bold">Privacy Policy</h1>
            <h5>Effective as of October 7, 2019</h5>
        </div>

        <p class="container text-left">
            <br><strong>Overview</strong><br>
            Sypher Music ("Sypher", "we", "us" or "our") is committed to protecting the privacy of personal information
            of individuals who visit the www.sypher.app website (the "Site") or mobile application (the “App”) and use
            the services available thereon (the "Services"). Amendments to this Privacy Policy will be posted to the
            Site and will be effective when posted. Your continued use of the Services following the posting of any
            amendment to the Privacy Policy shall constitute your acceptance of such amendment. You should periodically
            review this Privacy Policy and check the "Effective as of" date at the top of the document to familiarize
            yourself with the most recent version of this Privacy Policy.

            <br><br><strong>Information Collection and Use</strong><br>

            For a better experience, while using our Service, we may require you to provide us with certain personally
            identifiable information, including but not limited to full name, email address, phone number, log data. The
            information that we request is retained by us and used as described in this privacy policy. The app does use
            third party services that may collect information used to identify you.

            Link to privacy policy of third party service providers that we currently use or plan on using in the future

            <ul style="list-style-type:disc; text-align:left;" class="mb-0">
                <li><a href="https://www.google.com/policies/privacy/">Google Play Services &
                        Google Analytics</a></li>
                <li><a href="https://firebase.google.com/policies/analytics">Firebase
                        Analytics</a></li>
            </ul>

            <br><strong>Log Data</strong><br>

            We want to inform you that whenever you use our Service, in a case of an error in the app we collect
            data and information (through third party products) on your phone or browser called Log Data. This Log
            Data may include information such as your device Internet Protocol ("IP") address, device name,
            operating system version, the configuration of the app when utilizing our Service, the time and date of
            your use of the Service, and other statistics.

            <br><br><strong>Cookies</strong><br>

            A cookie is a string of information that a website stores on a visitor’s computer, and that the
            visitor’s browser provides to the website each time the visitor returns. We use cookies to help identify
            and track visitors, their usage of the Sypher website. Visitors who do not wish to have cookies placed
            on their computers should set their browsers to refuse cookies before using our services, with the
            drawback that certain features of our services may not function properly without the aid of cookies.

            <br><br><strong>Service Providers</strong><br>

            We may employ third-party companies and individuals due to the following reasons:
            <ul style="list-style-type:disc; text-align:left;">
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
            </ul>

            We want to inform users of this Service that these third parties have access to your Personal
            Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other purpose.

            <br><br><strong>Surveys & Givaways</strong><br>

            From time-to-time our site requests information via surveys or contests. Participation in these surveys or contests is completely voluntary and you may choose whether or not to participate and therefore disclose this information. Information requested may include contact information (such as name and shipping address), and demographic information (such as zip code, age level). Survey information will be used for purposes of monitoring or improving the use and satisfaction of this site.

            <br><br><strong>Do Not Track</strong><br>

            We do not track our customers over time and across third party websites to provide targeted
            advertising and therefore does not respond to Do Not Track (DNT) signals. However, some third party
            sites do keep track of your browsing activities when they serve you content, which enables them to
            tailor what they present to you. If you are visiting such sites, your browser may allow you to set
            the DNT signal on your browser so that third parties (particularly advertisers) know you do not want
            to be tracked. Third parties that have content embedded on our website such as a social feature may
            set cookies on a user’s browser and/or obtain information about the fact that a web browser visited
            a specific Astro Media Inc website from a certain IP address. Third parties cannot collect any other
            personally identifiable information from Astro Media Inc’s websites unless you provide it to them
            directly.

            <br><br><strong>Security of Your Information</strong><br>

            We take steps to ensure that your information is treated securely and in accordance with this
            Privacy Policy. Unfortunately, the Internet cannot be guaranteed to be 100% secure, and we cannot
            ensure or warrant the security of any information you provide to us.

            <br><br><strong>California Online Privacy Protection Act</strong><br>

            CalOPPA is the first state law in the nation to require commercial websites and online services to
            post a privacy policy. The law’s reach stretches well beyond California to require any person or
            company in the United States (and conceivably the world) that operates websites collecting
            Personally Identifiable Information from California consumers to post a conspicuous privacy policy
            on its website stating exactly the information being collected and those individuals or companies
            with whom it is being shared. - See more at: <a
                href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
                >california-online-privacy-protection</a>
        </p>

    </section>
</div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>

